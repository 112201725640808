import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EnglandIcon, HungaryIcon, RomaniaIcon } from "../assets/flags";
import { sortBy } from "lodash";
import dayjs from "dayjs";
import { useEffect } from "react";

const LanguagePicker = () => {
  const { i18n } = useTranslation();

  const handleLanguageSelect = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
    dayjs.locale(event.target.value);
  };

  useEffect(() => {
    const localStorageLang = localStorage.getItem("i18nextLng");
    if (localStorageLang) {
      i18n.changeLanguage(localStorageLang);
    }
  }, []);

  const languageMenuItems = useMemo(() => {
    const fullMenuList = [
      { value: "en", icon: EnglandIcon, text: "English" },
      { value: "hu", icon: HungaryIcon, text: "Magyar" },
      { value: "ro", icon: RomaniaIcon, text: "Română" },
    ];

    return sortBy(fullMenuList, ({ value }) =>
      value === i18n.language ? 0 : 1
    );
  }, [i18n.language]);

  return (
    <FormControl variant="standard" sx={{ pt: { xs: 1, md: 0 } }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        defaultValue={i18n.language}
        onChange={handleLanguageSelect}
        disableUnderline
      >
        {languageMenuItems.map((languageItem) => {
          return (
            <MenuItem value={languageItem.value} key={languageItem.value}>
              <Stack direction="row" spacing={1}>
                <languageItem.icon />
                <Typography sx={{ display: { xs: "none", md: "block" } }}>
                  {languageItem.text}
                </Typography>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default LanguagePicker;
