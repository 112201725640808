import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { typedStoreHooks } from "../../../store";
import { useTranslation } from "react-i18next";

type SuccessDialogProps = {
  handleClose: () => void;
  open: boolean;
};

const SuccessDialog: React.FC<SuccessDialogProps> = ({ handleClose, open }) => {
  const { t } = useTranslation(["translation", "common"]);

  const { isLoading, reservationSuccessful, reservationErrorMessage } =
    typedStoreHooks.useStoreState(({ reservationState }) => reservationState);

  const { reset, loadReservationTimes } = typedStoreHooks.useStoreActions(
    (a) => a
  );

  const radioGroupRef = React.useRef<HTMLElement>(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const onClose = async () => {
    handleClose();
    reset();
    await loadReservationTimes();
  };

  return (
    <Dialog
      open={open}
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
      maxWidth="xs"
      keepMounted={true}
      TransitionProps={{ onEntering: handleEntering }}
    >
      <DialogTitle>
        {isLoading
          ? t("successDialog.reservationInProgress", { ns: "common" })
          : ""}
      </DialogTitle>
      <Box height={300} ml={4} mr={4} mb={1}>
        {isLoading ? (
          <Stack alignItems="center" justifyContent="center" mt={8}>
            <CircularProgress size={100} />
          </Stack>
        ) : reservationSuccessful ? (
          <Stack spacing={3}>
            <Typography variant="h4">
              {t("successDialog.reservationSuccessful", { ns: "common" })}
            </Typography>
            <Typography>
              {t("successDialog.reservationMessage", { ns: "common" })}
            </Typography>
            <Typography>
              {t("successDialog.emailInfo", { ns: "common" })}
            </Typography>
            <Button
              autoFocus
              onClick={onClose}
              variant="contained"
              startIcon={<ArrowBackIcon />}
            >
              {t("successDialog.closeButton", { ns: "common" })}
            </Button>
          </Stack>
        ) : (
          <Stack spacing={3}>
            <Typography variant="h4">
              {t("successDialog.errorOccurred", { ns: "common" })}
            </Typography>
            <Typography>
              {reservationErrorMessage ??
                t("successDialog.errorMessage", { ns: "common" })}
            </Typography>
            <Button
              autoFocus
              onClick={onClose}
              variant="contained"
              startIcon={<ArrowBackIcon />}
            >
              {t("successDialog.closeButton", { ns: "common" })}
            </Button>
          </Stack>
        )}
      </Box>
    </Dialog>
  );
};

export default SuccessDialog;
