import { Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { map } from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { typedStoreHooks } from "../../../store";
import { EscapeRoomsIds, ReservableTime } from "../../../types";
import dayjs from "dayjs";

type TimeSelectionComponentProps = {
  reservationTimes: { time: ReservableTime; available: boolean }[];
  selectedEscapeRoomId: EscapeRoomsIds;
};

const TimeSelectionComponent: React.FC<TimeSelectionComponentProps> = ({
  reservationTimes,
  selectedEscapeRoomId,
}) => {
  const { t } = useTranslation(["translation", "common"]);
  const handleDateTimeSelected = typedStoreHooks.useStoreActions(
    (actions) => actions.handleTimeSelected
  );

  const { selectedDate } = typedStoreHooks.useStoreState(
    (store) => store.reservationState
  );

  const isToday = useMemo(() => {
    const currentDate = dayjs();

    return (
      selectedDate.date() === currentDate.date() &&
      selectedDate.isSame(currentDate, "year") &&
      selectedDate.isSame(currentDate, "month")
    );
  }, [selectedDate]);

  const noSpotToday = useMemo(() => {
    return reservationTimes.filter((time) => time.available).length === 0;
  }, [reservationTimes]);

  console.log({ noSpotToday });

  return (
    <Stack>
      <Grid container spacing={1} width="100%">
        {map(reservationTimes, (reservationTime, index) => {
          const time = dayjs(reservationTime.time, "HH:mm");
          const currentTime = dayjs();

          const isTimeBefore = time.isBefore(currentTime);

          if (isToday && isTimeBefore) {
            return <></>;
          }

          return (
            <Tooltip
              title={t("reservationCard.alreadyReserved", { ns: "common" })}
              open={reservationTime.available ? false : undefined}
              placement="top"
              key={index}
            >
              <Grid item xs={3} key={index}>
                <Button
                  variant="contained"
                  color={"success"}
                  disabled={!reservationTime.available}
                  onClick={() =>
                    handleDateTimeSelected({
                      selectedEscapeRoomId: selectedEscapeRoomId,
                      reservationTimeId: index,
                      time: reservationTime.time,
                    })
                  }
                >
                  {reservationTime.available ? (
                    reservationTime.time
                  ) : (
                    <del>{reservationTime.time}</del>
                  )}
                </Button>
              </Grid>
            </Tooltip>
          );
        })}
      </Grid>
      {noSpotToday && (
        <Typography textAlign="center" mt={2}>
          {t("reservationCard.noSpotToday", { ns: "common" })}
        </Typography>
      )}
    </Stack>
  );
};

export default TimeSelectionComponent;
