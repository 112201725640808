import PeopleIcon from "@mui/icons-material/People";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import _, { sortBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import {
  EnglandIcon,
  HungaryIcon,
  RomaniaIcon,
} from "../../../../assets/flags";
import { typedStoreHooks } from "../../../../store";
import { ReservableTime } from "../../../../types";
import { CustomPickerDay, StyledNumberInput } from "../../../SharedComponents";
import PriceDisplayComponent from "../PriceDisplayComponent";
import { PricePlayerMap } from "../types";

type ReservationViewProps = {
  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
  reservationTimes?: { time: ReservableTime; available: boolean }[];
  pricePlayerMap: PricePlayerMap;
};

const ReservationView: React.FC<ReservationViewProps> = ({
  simpleValidator,
  pricePlayerMap,
  reservationTimes,
}) => {
  const { i18n, t } = useTranslation();

  const {
    selectedDate: dateTime,
    acceptedTerms,
    nrOfPlayers,
    clientPhone,
    clientName,
    clientEmail,
    clientLanguage,
    reservationTimeId,
    selectedEscapeRoomId,
    reCaptchaToken,
    reservationTimes: allReservationTimes,
  } = typedStoreHooks.useStoreState(({ reservationState }) => reservationState);

  const { updateFields, handleTimeSelected } = typedStoreHooks.useStoreActions(
    (actions) => actions
  );

  useEffect(() => {
    updateFields({ clientLanguage: i18n.language });
  }, [i18n.language]);

  console.log({ clientLanguage });

  const languageMenuItems = useMemo(() => {
    const fullMenuList = [
      { value: "en", icon: EnglandIcon, text: "English" },
      { value: "hu", icon: HungaryIcon, text: "Magyar" },
      { value: "ro", icon: RomaniaIcon, text: "Română" },
    ];

    return sortBy(fullMenuList, ({ value }) =>
      value === i18n.language ? 0 : 1
    );
  }, [i18n.language]);

  const handleLanguageSelect = (event: SelectChangeEvent) => {
    console.log({ lang: event.target.value });
    updateFields({ clientLanguage: event.target.value });
  };

  const validationFieldNames = useMemo(() => {
    return {
      name: t("reservationCard.form.name", { ns: "common" }).toLowerCase(),
      email: t("reservationCard.form.email", { ns: "common" }).toLowerCase(),
      phone: t("reservationCard.form.phone", { ns: "common" }).toLowerCase(),
      time: t("reservationCard.form.time", { ns: "common" }).toLowerCase(),
      terms: t("reservationCard.form.terms.link", {
        ns: "common",
      }).toLowerCase(),
      reCaptcha: "recaptcha",
    };
  }, [i18n.language]);

  const onRecaptchaChange = (token: string | null) => {
    updateFields({ reCaptchaToken: token });
  };

  if (!clientLanguage) {
    return (
      <Stack>
        <Skeleton variant="rounded" width={210} height={60} />
      </Stack>
    );
  }

  return (
    <Grid container spacing={3} alignItems="start">
      <Grid item xs={12}>
        <Typography>
          {t("experiment23.preReservationText", { ns: "common" })}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          defaultValue={clientName}
          label={t("reservationCard.form.name", { ns: "common" })}
          variant="standard"
          name="name"
          fullWidth
          onChange={_.debounce((e) => {
            updateFields({ clientName: e.target.value });
          }, 300)}
          required
          helperText={simpleValidator.current.message(
            validationFieldNames.name,
            clientName,
            ["required", { min: 4, max: 40 }]
          )}
          error={
            simpleValidator.current.messagesShown &&
            simpleValidator.current.fieldValid(validationFieldNames.name) ===
              false
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          defaultValue={clientEmail}
          label={t("reservationCard.form.email", { ns: "common" })}
          variant="standard"
          name="email"
          fullWidth
          onChange={_.debounce((e) => {
            updateFields({ clientEmail: e.target.value });
          }, 300)}
          required
          helperText={simpleValidator.current.message(
            validationFieldNames.email,
            clientEmail,
            ["required", "email", { min: 4, max: 60 }]
          )}
          error={
            simpleValidator.current.messagesShown &&
            simpleValidator.current.fieldValid(validationFieldNames.email) ===
              false
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={t("reservationCard.form.phone", { ns: "common" })}
          value={clientPhone}
          variant="standard"
          name="phone"
          fullWidth
          onChange={(event) => {
            updateFields({ clientPhone: event.target.value });
          }}
          required
          helperText={simpleValidator.current.message(
            validationFieldNames.phone,
            clientPhone,
            ["required", "phone"]
          )}
          error={
            simpleValidator.current.messagesShown &&
            simpleValidator.current.fieldValid(validationFieldNames.phone) ===
              false
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          variant="standard"
          sx={{ minWidth: 120 }}
          fullWidth
          required
        >
          <InputLabel id="game-lang-select-label">
            {t("reservationCard.form.language", { ns: "common" })}
          </InputLabel>
          <Select
            labelId="game-lang-select-label"
            id="game-lang-select"
            defaultValue={clientLanguage}
            onChange={handleLanguageSelect}
          >
            {languageMenuItems.map((languageItem) => {
              return (
                <MenuItem value={languageItem.value} key={languageItem.value}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <languageItem.icon />
                    <Typography> {languageItem.text}</Typography>
                  </Stack>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <MobileDatePicker
          label={t("reservationCard.form.date", { ns: "common" })}
          sx={{ width: "100%" }}
          value={dayjs(dateTime)}
          onChange={(newValue) => {
            if (newValue) {
              updateFields({
                selectedDate: newValue,
                reservationTimeId: undefined,
              });
            }
          }}
          dayOfWeekFormatter={(_day: string) => _day}
          maxDate={dayjs().add(60, "day")}
          disablePast
          slotProps={{
            textField: { variant: "standard", required: true },
          }}
          slots={{
            day: (props) => (
              <CustomPickerDay
                {...props}
                reservationTimesData={allReservationTimes?.data}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {reservationTimes && (
          <FormControl
            error={
              simpleValidator.current.messagesShown &&
              simpleValidator.current.fieldValid(validationFieldNames.time) ===
                false
            }
            variant="standard"
            sx={{ minWidth: 120 }}
            fullWidth
            required
          >
            <InputLabel id="time-select-label">
              {t("reservationCard.form.time", { ns: "common" })}
            </InputLabel>
            <Select
              labelId="time-select-label"
              id="time-select"
              defaultValue={reservationTimeId?.toString()}
              onChange={(e) =>
                handleTimeSelected({
                  reservationTimeId: Number(e.target.value),
                  selectedEscapeRoomId,
                  time: reservationTimes[Number(e.target.value)].time,
                })
              }
              name="time"
            >
              {reservationTimes.map((time, id) => {
                return (
                  <MenuItem value={id} disabled={!time.available} key={id}>
                    <Typography>
                      {time.available ? time.time : <del>{time.time}</del>}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {simpleValidator.current.message(
                validationFieldNames.time,
                reservationTimeId,
                ["required"]
              )}
            </FormHelperText>
          </FormControl>
        )}
      </Grid>
      <Grid item md={6}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <PeopleIcon />
            <Typography>
              {t("reservationCard.players", { ns: "common" })}
            </Typography>
          </Stack>
          <StyledNumberInput
            min={2}
            max={10}
            value={nrOfPlayers}
            onChange={(_e, val) => {
              updateFields({ nrOfPlayers: val });
            }}
            required
          />
        </Stack>
      </Grid>
      <Grid item md={6} xs={12}>
        <PriceDisplayComponent
          nrOfPlayers={nrOfPlayers ?? 4}
          pricePlayerMap={pricePlayerMap}
        />
      </Grid>
      <Grid item md={12}>
        <FormControl
          error={
            simpleValidator.current.messagesShown &&
            simpleValidator.current.fieldValid("terms") === false
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                value={acceptedTerms}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateFields({ acceptedTerms: event.target.checked });
                }}
                name="terms"
              />
            }
            label={
              <Typography>
                {t("reservationCard.form.terms.prefix", { ns: "common" })}
                <Link href={"/terms"} target="_blank" rel="noreferrer">
                  {t("reservationCard.form.terms.link", { ns: "common" })}
                </Link>
                {t("reservationCard.form.terms.suffix", { ns: "common" })}
              </Typography>
            }
          />
          <FormHelperText>
            {simpleValidator.current.message(
              validationFieldNames.terms,
              acceptedTerms,
              ["accepted"]
            )}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item md={12}>
        <FormControl
          error={
            simpleValidator.current.messagesShown &&
            simpleValidator.current.fieldValid(
              validationFieldNames.reCaptcha
            ) === false
          }
          variant="standard"
          sx={{ minWidth: 120 }}
          fullWidth
          required
        >
          <ReCAPTCHA
            sitekey={"6LdNzxkoAAAAANZNvhZjwUbuBNWEhFddDYlf3WVg"}
            onChange={onRecaptchaChange}
          />
          <FormHelperText>
            {simpleValidator.current.message(
              validationFieldNames.reCaptcha,
              reCaptchaToken,
              ["required"]
            )}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ReservationView;
