import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {
  Box,
  Button,
  CardMedia,
  CardMediaProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { typedStoreHooks } from "../../../../store";
import {
  MediaText,
  SimpleValidatorExtended as SimpleReactValidator,
  TransparentMediaText,
} from "../../../SharedComponents";
import { PricePlayerMap } from "../types";
import DetailsView from "./DetailsView";
import ReservationView from "./ReservationView";
import { useTranslation } from "react-i18next";
import { ReservableTime } from "../../../../types";
import { makeReservation } from "./reservationHelper";
import { TimeIcon } from "@mui/x-date-pickers";
import PeopleIcon from "@mui/icons-material/People";
import { AxiosError } from "axios";
import LightBulbIconFilled from "@mui/icons-material/EmojiObjects";
import LightBulbIconEmpty from "@mui/icons-material/EmojiObjectsOutlined";

export interface RoomCardDialogProps {
  keepMounted: boolean;
  open: boolean;
  onClose: (value?: string) => void;
  title: string;
  imageProps?: CardMediaProps;
  reservationTimes?: { time: ReservableTime; available: boolean }[];
  pricePlayerMap: PricePlayerMap;
  isReservationView: boolean;
  goToReservationView: () => void;
  duration: number;
  difficulty: number;
}

const RoomCardDialog: React.FC<RoomCardDialogProps> = ({
  keepMounted,
  open,
  onClose,
  title,
  imageProps,
  reservationTimes,
  pricePlayerMap,
  isReservationView,
  duration,
  difficulty,
  goToReservationView,
}) => {
  const { i18n, t } = useTranslation();

  const [_forceRerender, setForceRerender] = useState(false);

  const simpleValidator = useRef(
    new SimpleReactValidator({ locale: i18n.language })
  );

  const {
    clientName,
    clientEmail,
    clientPhone,
    nrOfPlayers,
    acceptedTerms,
    clientLanguage,
    selectedDate,
    selectedEscapeRoomId,
    reservationTime,
    reCaptchaToken,
  } = typedStoreHooks.useStoreState(({ reservationState }) => reservationState);

  const { updateFields } = typedStoreHooks.useStoreActions((a) => a);

  const radioGroupRef = React.useRef<HTMLElement>(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const onReservationSuccess = () => {
    updateFields({ isLoading: false, reservationSuccessful: true });
    onClose();
  };

  const onReservationFailed = () => {
    updateFields({ isLoading: false, reservationSuccessful: false });
    onClose();
  };

  const onConflict = () => {
    updateFields({
      reservationErrorMessage: t("reservationErrors.conflict", {
        ns: "common",
      }).toString(),
    });
    onReservationFailed();
  };

  const onSubmit = async () => {
    if (simpleValidator.current.allValid()) {
      updateFields({ isLoading: true });
      try {
        await makeReservation({
          clientName,
          clientEmail,
          clientPhone,
          nrOfPlayers,
          acceptedTerms,
          clientLanguage,
          selectedDate,
          selectedEscapeRoomId,
          reservationTime,
          reCaptchaToken,
        });

        onReservationSuccess();
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          switch (axiosError.response.status) {
            case 406:
            case 418:
              onReservationFailed();
              break;
            case 409:
              onConflict();
              break;
            default:
              console.log("Error:", axiosError.message);
          }
        } else {
          console.log("Error:", axiosError.message);
        }
      }
    } else {
      simpleValidator.current.showMessages();
      setForceRerender((prev) => !prev);
    }
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "100%" } }}
      maxWidth="lg"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      keepMounted={keepMounted}
    >
      <DialogTitle variant="h4">{title}</DialogTitle>{" "}
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item lg={5} md={6} xs={12}>
            <Stack
              sx={{
                height: { md: 450, xs: 200 },
                borderRadius: 5,
                overflow: "hidden",
                opacity: 0.8,
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  height: { md: "310px" },
                  minHeight: { md: "310px" },
                  minWidth: "100%",
                  position: "relative",
                  overflow: "hidden",
                  transform: {
                    md: "scale(1.2) translate(15px, 20px)",
                    sm: "scale(9) translate(1px, 17px)",
                    xs: "scale(6) translate(2px, 17px)",
                  },
                  transition: "1.5s",
                  zIndex: 9,
                  objectFit:
                    "contain" /* Prevents image sides from being cut off */,
                }}
                {...imageProps}
              />
              <TransparentMediaText
                sx={{
                  transition: "all .2s ease-in-out",
                  height: { md: "fit-content" },
                  zIndex: 10,
                  transform: {
                    md: "none",
                    xs: "translate(0px, -30px)",
                  },
                  backgroundColor: {
                    md: "rgba(0,0,0,0.95)",
                    xs: "rgba(0,0,0,0.65)",
                  },
                }}
              >
                <Stack spacing={2} alignItems={{ xs: "center", md: "start" }}>
                  <Stack direction="row" spacing={1} pt={2}>
                    <Typography variant="body1">
                      {t("reservationDetails.duration", { ns: "common" })}:{" "}
                    </Typography>
                    <TimeIcon />
                    <Typography variant="body1">
                      {duration}{" "}
                      {t("reservationCard.durationSuffix", { ns: "common" })}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="body1">
                      {" "}
                      {t("reservationDetails.difficulty", {
                        ns: "common",
                      })}
                      :{" "}
                    </Typography>
                    <Rating
                      defaultValue={difficulty}
                      size="medium"
                      disabled
                      precision={0.5}
                      icon={<LightBulbIconFilled fontSize="inherit" />}
                      emptyIcon={<LightBulbIconEmpty fontSize="inherit" />}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="body1">
                      {t("reservationDetails.maxGroupSize", { ns: "common" })}:{" "}
                    </Typography>
                    <PeopleIcon />
                    <Typography variant="body1">5</Typography>
                  </Stack>
                </Stack>
              </TransparentMediaText>
            </Stack>
          </Grid>
          <Grid item lg={7} md={6} xs={12}>
            {isReservationView ? (
              <ReservationView
                pricePlayerMap={pricePlayerMap}
                simpleValidator={simpleValidator}
                reservationTimes={reservationTimes}
              />
            ) : (
              <DetailsView duration={duration} difficulty={difficulty} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          variant="contained"
          startIcon={<ArrowBackIcon />}
        >
          {t("reservationPopup.Back", { ns: "common" })}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          onClick={isReservationView ? onSubmit : goToReservationView}
          endIcon={
            isReservationView ? (
              <ShoppingCartCheckoutIcon />
            ) : (
              <ArrowForwardIcon />
            )
          }
        >
          {isReservationView
            ? t("reservationPopup.makeReservation", { ns: "common" })
            : t("reservationDetails.toReservation", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoomCardDialog;
