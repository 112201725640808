import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";

const fire = firebase.initializeApp({
  apiKey: "AIzaSyBfdrJXHc45YztUZmYysyGWr9vYeFOKAPE",
  authDomain: "playfull-b8631.firebaseapp.com",
  projectId: "playfull-b8631",
  storageBucket: "playfull-b8631.appspot.com",
  messagingSenderId: "400698266024",
  appId: "1:400698266024:web:f953866957f4b78a2e8d9d",
  measurementId: "G-XK0N9HLKGL",
});

export const firestoreDB = getFirestore(fire);

export default fire;
