import { Box, Typography, Stack } from "@mui/material";
import { map } from "lodash";
import React, { useMemo } from "react";
import FAQCardComponent from "./FAQCardComponent";
import { useTranslation } from "react-i18next";

const FAQList = [
  "whatIsIt",
  "ageLimit",
  "payment",
  "scariness",
  "whatToBring",
  "genius",
  "groupSize",
];

const FAQComponent = () => {
  const { i18n, t } = useTranslation();

  const FAQq = useMemo(() => {
    return map(FAQList, (faq, index) => {
      return {
        title: t(`FAQ.${faq}.question`, { ns: "common" }),
        description: t(`FAQ.${faq}.answer`, { ns: "common" }),
      };
    });
  }, [i18n.language]);

  return (
    <Box mx={{ md: 20, xs: 4 }} mt={8}>
      <Typography variant="h4" mb={6}>
        {t("FAQ.title", { ns: "common" })}
      </Typography>
      <Stack spacing={3}>
        {map(FAQq, (faq, index) => (
          <FAQCardComponent
            title={faq.title}
            description={faq.description}
            key={index}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default FAQComponent;
