export const getCurrentTimeString = (): string => {
  const now = new Date();

  return (
    now.getHours().toString().padStart(2, "0") +
    ":" +
    now.getMinutes().toString().padStart(2, "0")
  );
};

export function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };

  const currentLanguage = localStorage.getItem("i18nextLng") ?? "en";

  const formattedDate = new Intl.DateTimeFormat(
    currentLanguage,
    options
  ).format(date);
  return formattedDate;
}

export function isToday(date: Date) {
  return new Date().toDateString() === date.toDateString();
}
