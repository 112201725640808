/**
 * The unit price for an escape game, based on the number of players. To get the total price, multiply the value with its index
 */
export type PricePlayerMap = {
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
  8: number;
  9: number;
  10: number;
};

export enum ReservationPopupState {
  RESERVATION_VIEW,
  DETAILS_VIEW,
  CLOSED,
}
