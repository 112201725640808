import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type DetailsViewProps = {
  duration: number;
  difficulty: number;
};

const DetailsView: React.FC<DetailsViewProps> = ({ difficulty, duration }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant="h5">
        {" "}
        {t("experiment23.details.firstSubtitile", { ns: "common" })}
      </Typography>
      <Typography variant="body1">
        {t("experiment23.details.firstParagraph", { ns: "common" })}
      </Typography>
      <Typography variant="h5">
        {" "}
        {t("experiment23.details.secondSubtitle", { ns: "common" })}
      </Typography>
      <Typography variant="body1">
        {t("experiment23.details.secondParagraph", { ns: "common" })}
      </Typography>
      <Typography variant="body2" color="GrayText">
        {t("experiment23.details.secretParagraph", { ns: "common" })}
      </Typography>
    </Stack>
  );
};

export default DetailsView;
