import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ValidateReservation = () => {
  const { t } = useTranslation(["translation", "common"]);

  return (
    <Stack alignItems="center" pt={8}>
      <Typography variant="h2" textAlign="center">
        {t("validation.title", { ns: "common" })}
      </Typography>
      <Typography variant="h5" textAlign="center">
        {t("validation.description", { ns: "common" })}
      </Typography>
    </Stack>
  );
};

export default ValidateReservation;
