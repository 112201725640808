import axios from "axios";
import dayjs from "dayjs";

type ReservationPayload = {
  selectedDate: dayjs.Dayjs;
  selectedEscapeRoomId: number;
  nrOfPlayers?: number;
  clientName?: string;
  clientEmail?: string;
  clientPhone?: string;
  clientLanguage?: string;
  reservationTime?: string;
  acceptedTerms?: boolean;
  reCaptchaToken: string | null;
};

export const makeReservation = (payload: ReservationPayload) => {
  return axios.post(
    "https://handlereservation-3yupj4jrvq-uc.a.run.app/",
    // process.env.REACT_APP_SERVER_URL ?? "",
    { ...payload, selectedDate: payload.selectedDate.format("YYYY-MM-DD") }
  );
};
