import TimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PsychologyIcon from "@mui/icons-material/Psychology";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardMediaProps,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { typedStoreHooks } from "../../../store";
import {
  EscapeRoomsIds,
  ReservableTime,
  ReservationStep,
} from "../../../types";
import { MediaText } from "../../SharedComponents";
import RoomCardDialog from "./ConfirmationDialog/RoomCardDialog";
import PersonCountSelectionComponent from "./PersonCountSelectionComponent";
import SuccessDialog from "./SuccessDialog";
import TimeSelectionComponent from "./TimeSelectionComponent";
import { PricePlayerMap, ReservationPopupState } from "./types";

type RoomCardProps = {
  escapeRoomId: EscapeRoomsIds;
  imageProps?: CardMediaProps;
  secondaryImageProps?: CardMediaProps;
  title: string;
  shortDescription: string;
  reservationTimes?: { time: ReservableTime; available: boolean }[];
  duration: number;
  pricePlayerMap: PricePlayerMap;
  difficulty?: number;
};

const RoomCard: React.FC<RoomCardProps> = ({
  escapeRoomId,
  imageProps,
  secondaryImageProps,
  title,
  shortDescription,
  reservationTimes,
  duration,
  pricePlayerMap,
  difficulty,
}) => {
  const { t } = useTranslation(["translation", "common"]);
  const { step, selectedEscapeRoomId, reservationTimeId, isLoading } =
    typedStoreHooks.useStoreState(({ reservationState }) => reservationState);

  const updateFields = typedStoreHooks.useStoreActions(
    (actions) => actions.updateFields
  );

  const [reservationPopupState, setReservationPopupState] =
    useState<ReservationPopupState>(ReservationPopupState.CLOSED);

  const [reservationSentPopupOpen, setReservationSentPopupOpen] =
    useState(false);

  useEffect(() => {
    if (isLoading) {
      setReservationSentPopupOpen(true);
    }
  }, [isLoading]);

  const closeReservationSentPopup = () => {
    setReservationSentPopupOpen(false);
  };

  const closeReservationPopup = () => {
    setReservationPopupState(ReservationPopupState.CLOSED);
  };

  const openReservationPopup = () => {
    updateFields({ selectedEscapeRoomId: escapeRoomId });
    setReservationPopupState(ReservationPopupState.RESERVATION_VIEW);
  };

  const openDetailsPopup = () => {
    setReservationPopupState(ReservationPopupState.DETAILS_VIEW);
  };

  const dialogOpen = useMemo(
    () => reservationPopupState !== ReservationPopupState.CLOSED,
    [reservationPopupState]
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width={330}>
      <Card
        sx={{
          maxWidth: 370,
          transition: "0.2s",
          ":hover": {
            transform: "scale(1.04)",
            boxShadow: `0 1px 10px 0.5px rgb(69, 69, 69)`,
          },
        }}
      >
        <div style={{ position: "relative" }} onClick={openDetailsPopup}>
          <Box maxHeight={220} overflow={"hidden"}>
            <CardMedia
              sx={{
                height: 220,
                maxHeight: 220,
                cursor: "pointer",
                transform: {
                  md: "scale(2.1) translate(11px, -24px)",
                  xs: "none",
                },
                transition: "1.5s",
                ":hover": {
                  transform: "none",
                  boxShadow: `0 1px 10px 0.5px rgb(69, 69, 69)`,
                },
              }}
              {...imageProps}
            />
          </Box>
          <Box sx={{ cursor: "pointer", transition: "all .2s ease-in-out" }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box mt={1} width={40} height={40}>
                {step === ReservationStep.TIME_SELECTED && (
                  <IconButton
                    aria-label="back"
                    onClick={(e) => {
                      e.stopPropagation();
                      updateFields({ step: ReservationStep.INITIAL_STEP });
                    }}
                  >
                    {" "}
                    <ArrowBackIcon />
                  </IconButton>
                )}
              </Box>
              <Typography variant="h5">{title}</Typography>
              <Box width={40} height={40}></Box>
            </Stack>
          </Box>
        </div>
        <CardContent>
          <Stack direction="row" spacing={4}>
            <Stack direction="row" mb={0} spacing={1}>
              <TimeIcon />{" "}
              <Box mr={5}>
                {duration}{" "}
                {t("reservationCard.durationSuffix", { ns: "common" })}
              </Box>
            </Stack>
            <Stack direction="row" mb={0} spacing={1}>
              <PsychologyIcon />{" "}
              <Box>
                {difficulty ?? 0}/5{" "}
                {t("reservationCard.difficultySuffix", { ns: "common" })}
              </Box>
            </Stack>
          </Stack>
          {!(
            step >= ReservationStep.TIME_SELECTED &&
            escapeRoomId === selectedEscapeRoomId
          ) && (
            <Typography mt={2} variant="body2" color="text.secondary">
              {shortDescription}{" "}
              <Link onClick={openDetailsPopup} sx={{ cursor: "pointer" }}>
                {t("reservationCard.moreDetails", { ns: "common" })}
              </Link>
            </Typography>
          )}
        </CardContent>
        <Box ml={2} mr={2}>
          <Divider light />
        </Box>

        <CardActions>
          <Box m={1} mb={2} width="100%">
            {selectedEscapeRoomId === escapeRoomId &&
            step >= ReservationStep.TIME_SELECTED ? (
              <PersonCountSelectionComponent
                pricePlayerMap={pricePlayerMap}
                selectedReservationTime={
                  reservationTimes?.[reservationTimeId ?? 1]?.time
                }
                toggleReservationPopup={openReservationPopup}
              />
            ) : reservationTimes ? (
              <TimeSelectionComponent
                reservationTimes={reservationTimes}
                selectedEscapeRoomId={escapeRoomId}
              />
            ) : (
              <Skeleton height={80} variant="rounded" />
            )}
          </Box>
        </CardActions>
      </Card>
      {dialogOpen && (
        <RoomCardDialog
          duration={duration}
          difficulty={difficulty ?? 0}
          keepMounted
          open={dialogOpen}
          onClose={closeReservationPopup}
          title={title}
          imageProps={imageProps}
          reservationTimes={reservationTimes}
          pricePlayerMap={pricePlayerMap}
          isReservationView={
            reservationPopupState === ReservationPopupState.RESERVATION_VIEW
          }
          goToReservationView={openReservationPopup}
        />
      )}

      {reservationSentPopupOpen && (
        <SuccessDialog
          open={reservationSentPopupOpen}
          handleClose={closeReservationSentPopup}
        />
      )}
    </Box>
  );
};

export default RoomCard;
