import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StoreProvider } from "easy-peasy";
import { initializeApp } from "firebase/app";
import "typeface-andika";
import PlayFullPage from "./components/PlayFullPageComponent";
import { store } from "./store";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: { main: "#df8429" },
      secondary: { main: "#2984DF" },
      text: { primary: "#f5f5f5" },
      success: { main: "#29DF84" },
    },
    typography: {
      fontFamily: "Andika, Roboto, sans-serif",
      button: {
        fontWeight: 600,
      },
    },
  });

  darkTheme.typography.h2 = {
    ...darkTheme.typography.h2,
    [darkTheme.breakpoints.down("md")]: {
      fontSize: "2.2rem",
    },
    [darkTheme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
  };

  darkTheme.typography.h3 = {
    ...darkTheme.typography.h3,
    [darkTheme.breakpoints.down("md")]: {
      fontSize: "1.9rem",
    },
    [darkTheme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
  };

  darkTheme.typography.h4 = {
    ...darkTheme.typography.h4,
    [darkTheme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [darkTheme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  };

  darkTheme.typography.h5 = {
    ...darkTheme.typography.h5,
    [darkTheme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [darkTheme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
  };

  darkTheme.typography.h6 = {
    ...darkTheme.typography.h5,
    [darkTheme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
    [darkTheme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkTheme}>
        <StoreProvider store={store}>
          <CssBaseline />

          <PlayFullPage />
        </StoreProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
