import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { LogoWithText, PlayFullLogo } from "../assets";
import MainBackgroundImage from "../assets/images/PageBG.png";
import { typedStoreHooks } from "../store";
import { AboutComponent } from "./About";
import { FAQComponent } from "./FAQ";
import { GamesComponent } from "./Games";
import LanguagePicker from "./LanguagePicker";
import { ReservationComponent } from "./Reservation";
import { ScrollToAnchor } from "./SharedComponents";
import ValidateReservation from "./SharedComponents/ValidateReservation";
import TermsAndConditionsComponent from "./Terms/TermsAndConditions";

const tabProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    component: Link,
  };
};

const tabs = {
  home: 0,
  games: 1,
  reservation: 2,
  contact: 3,
  faq: 4,
};

const pages = [
  { name: "home", route: "/home#about" },
  { name: "games", route: "/games" },
  { name: "reservation", route: "/reservation" },
  { name: "contact", route: "/home#contact" },
  { name: "faq", route: "/faq" },
];

export default function PlayFullPage() {
  const { t } = useTranslation(["translation", "common"]);
  const [tab, setTab] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box m={2}>
        <LogoWithText
          width={80}
          height={80}
          onClick={() => navigate("/home")}
        />
      </Box>
      <Divider />
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="simple tabs example"
        orientation="vertical"
      >
        {pages.map((page, pageIndex) => (
          <Tab
            label={t(`tabs.${page.name}`, { ns: "common" })}
            to={page.route}
            key={pageIndex}
            {...tabProps(pageIndex)}
          />
        ))}
      </Tabs>
    </Box>
  );

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const loadReservationTimes = typedStoreHooks.useStoreActions(
    ({ loadReservationTimes }) => loadReservationTimes
  );

  useEffect(() => {
    console.log("effect called");
    loadReservationTimes();
  }, []);

  useEffect(() => {
    const pathTokenized = pathname.split("/");
    if (pathTokenized.length > 1) {
      setTab(
        tabs[pathTokenized[1] as "home" | "games" | "reservation" | "contact"]
      );
    }
  }, [pathname]);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Stack
      sx={{
        backgroundImage: `url(${MainBackgroundImage})`,
        height: "100%",
        minHeight: "100vh",
        backgroundPosition: "bottom -100px right -250px",
        backgroundAttachment: "fixed",
        width: "100vw",
      }}
      direction="column"
      alignItems="center"
    >
      <ScrollToAnchor />

      <AppBar
        position="fixed"
        sx={{
          width: "100vw",
          left: 0,
          right: 0,
          paddingRight: "0 !important",
          backgroundColor: "rgba(0,0,0,0.9)",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                  alignItems: "center",
                },
                my: 1,
              }}
            >
              <Box sx={{ cursor: "pointer" }}>
                <LogoWithText
                  width={70}
                  height={70}
                  onClick={() => navigate("/home")}
                />
              </Box>
              <Typography
                fontStyle="italic"
                ml={6}
                color="#e0a971"
                sx={{
                  display: {
                    xs: "none",
                    lg: "block",
                  },
                }}
              >
                {t("logoDescription", { ns: "common" })}
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, my: 1 }}
            >
              <PlayFullLogo
                width={50}
                height={50}
                onClick={() => navigate("/home")}
              />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "right",
              }}
            >
              <Tabs
                value={tab}
                onChange={handleChange}
                aria-label="simple tabs example"
                sx={{ mr: 4 }}
              >
                {pages.map((page, pageIndex) => (
                  <Tab
                    label={t(`tabs.${page.name}`, { ns: "common" })}
                    to={page.route}
                    key={pageIndex}
                    {...tabProps(pageIndex)}
                  />
                ))}
              </Tabs>
            </Box>

            <Box>
              <LanguagePicker />
            </Box>

            {/*  */}
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 150 },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <Box minHeight="calc(100vh - 72px)" pt={6} maxWidth="1200px">
        <Routes>
          <Route path="/home" element={<AboutComponent />} />
          <Route path="/games" element={<GamesComponent />} />
          <Route path="/reservation" element={<ReservationComponent />} />
          {/* <Route
            path="/kultReservation"
            element={<KultReservationComponent />}
          /> */}
          <Route path="/faq" element={<FAQComponent />} />
          <Route path="terms" element={<TermsAndConditionsComponent />} />
          <Route path="/validate" element={<ValidateReservation />} />
          <Route path="/*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Box>
      <footer>
        <Box
          mt={2}
          pt={2}
          pb={2}
          sx={{
            backgroundColor: "rgba(31, 31, 31, 0.65)",
          }}
          width="100vw"
        >
          <Typography textAlign="center">
            {t("footer", { ns: "common" })}
          </Typography>
        </Box>
      </footer>
      {/* <PageInDevDialog handleClose={closeDevPopup} open={devPopupOpen} /> */}
    </Stack>
  );
}
