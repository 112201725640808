import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const GamesComponent = () => {
  const { t } = useTranslation();

  return (
    <Box m={16}>
      <Typography variant="h3" textAlign="center">
        {t("gamesScreen.comingSoon", { ns: "common" })} ;&#41;
      </Typography>
    </Box>
  );
};

export default GamesComponent;
