import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardMediaProps,
  Stack,
  Typography,
} from "@mui/material";
import { DefaultTFuncReturn } from "i18next";

type PresenterCardComponentProps = {
  imageProps?: CardMediaProps;
  paperColor?: string;
  title: string;
  description?: string | DefaultTFuncReturn;
  onClick: () => void;
};

const PresenterCardComponent: React.FC<PresenterCardComponentProps> = ({
  imageProps,
  description,
  title,
  paperColor,
  onClick,
}) => {
  return (
    <Card
      sx={{
        width: 280,
        backgroundColor: paperColor,
        borderRadius: 8,
        transition: "0.2s",
        ":hover": {
          transform: "scale(1.1)",
          boxShadow: `0 2px 20px 4px rgb(69, 69, 69)`,
        },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <CardMedia sx={{ height: 200 }} {...imageProps} />
      </Box>
      <CardContent sx={{ height: 170 }}>
        <Stack alignItems="center" spacing={2}>
          <Box
            sx={{
              transform: !!description ? "scale(1)" : "scale(1.2)",
              transition: "0.2s",
              pt: !!description ? 0 : 4,
            }}
          >
            <Typography variant="h4">{title}</Typography>
          </Box>
          <Typography variant="h6" textAlign="center">
            {description}
          </Typography>
        </Stack>
      </CardContent>

      <CardActions></CardActions>
    </Card>
  );
};

export default PresenterCardComponent;
