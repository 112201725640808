import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { PricePlayerMap } from "./types";
import { useTranslation } from "react-i18next";

type PriceDisplayComponentProps = {
  pricePlayerMap: PricePlayerMap;
  nrOfPlayers: number;
};

const PriceDisplayComponent: React.FC<PriceDisplayComponentProps> = ({
  nrOfPlayers,
  pricePlayerMap,
}) => {
  const { t } = useTranslation(["translation", "common"]);

  return (
    <Grid item xs={6}>
      <Stack spacing={0}>
        <Typography>
          {pricePlayerMap[nrOfPlayers as keyof PricePlayerMap]} lei /{" "}
          {t("reservationCard.person", { ns: "common" })}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2" color="text.secondary">
            {" "}
            Total:
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {pricePlayerMap[nrOfPlayers as keyof PricePlayerMap] * nrOfPlayers}{" "}
            lei
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default PriceDisplayComponent;
