import { NumberInputProps } from "@mui/base";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PeopleIcon from "@mui/icons-material/People";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { typedStoreHooks } from "../../../store";
import { StyledNumberInput } from "../../SharedComponents";
import { formatDate } from "../helpers";
import PriceDisplayComponent from "./PriceDisplayComponent";
import { PricePlayerMap } from "./types";

type PersonCountSelectionComponentProps = {
  pricePlayerMap: PricePlayerMap;
  selectedReservationTime?: string;
  toggleReservationPopup: () => void;
};

const PersonCountSelectionComponent: React.FC<
  PersonCountSelectionComponentProps
> = ({ pricePlayerMap, selectedReservationTime, toggleReservationPopup }) => {
  const { t } = useTranslation(["translation", "common"]);

  const { selectedDate: dateTime, nrOfPlayers } = typedStoreHooks.useStoreState(
    ({ reservationState }) => reservationState
  );

  const updateFields = typedStoreHooks.useStoreActions(
    (actions) => actions.updateFields
  );

  const onPlayerCountChange: NumberInputProps["onChange"] = (_e, val) => {
    updateFields({ nrOfPlayers: val });
  };
  return (
    <Grid container flexDirection="row" spacing={3} alignItems="center">
      <Grid item xs={8}>
        <Stack direction="row" spacing={1}>
          <EventAvailableIcon />
          <Typography variant="body1">
            {" "}
            {formatDate(new Date(dateTime.toDate()))}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction="row" spacing={1}>
          <ScheduleIcon />
          <Typography> {selectedReservationTime}</Typography>
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Stack direction="row" spacing={1}>
            <PeopleIcon />
            <Typography>
              {t("reservationCard.players", { ns: "common" })}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <StyledNumberInput
            min={2}
            max={10}
            defaultValue={nrOfPlayers ?? 4}
            onChange={onPlayerCountChange}
          />
        </Grid>
      </Grid>
      {nrOfPlayers ? (
        <PriceDisplayComponent
          nrOfPlayers={nrOfPlayers}
          pricePlayerMap={pricePlayerMap}
        />
      ) : (
        <Typography>-</Typography>
      )}
      <Grid item>
        <Button
          variant="contained"
          startIcon={<AddShoppingCartIcon />}
          size="large"
          onClick={toggleReservationPopup}
          color="secondary"
        >
          {t("reservationCard.reserve", { ns: "common" })}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PersonCountSelectionComponent;
