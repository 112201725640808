// @ts-nocheck
import SimpleReactValidator from "simple-react-validator";

SimpleReactValidator.addLocale("hu", {
  accepted: "A :attribute elfogadása szűkséges a foglaláshoz.",
  required: "A :attribute nem lehet üres.",
  phone: "A telefonszámnak valósnak kell lennie.",
  email: "Az email címnek valósnak kell lennie.",
  min: "A :attribute nem lehet rövidebb mint :min karakter.",
  max: "A :attribute nem lehet hosszabb mint :max karakter.",
});

// Todo: add ro locale
SimpleReactValidator.addLocale("hu", {
  accepted: "A :attribute elfogadása szűkséges a foglaláshoz.",
  required: "A :attribute nem lehet üres.",
  phone: "A telefonszámnak valósnak kell lennie.",
  email: "Az email címnek valósnak kell lennie.",
  min: "A :attribute nem lehet rövidebb mint :min karakter.",
  max: "A :attribute nem lehet hosszabb mint :max karakter.",
});

export default SimpleReactValidator;
