import { Box, BoxProps, alpha, styled } from "@mui/material";

export const MediaText = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  color: "white",
  width: "100%",
  left: "50%",
  top: 0,
  transform: "translateX(-50%)",
  backgroundImage: `linear-gradient(to top, ${alpha(
    theme.palette.background.default,
    0.4
  )}, ${alpha(theme.palette.background.default, 0.75)})`,
  // backgroundColor: alpha(theme.palette.background.default, 0.55),
  padding: theme.spacing(2),
  textAlign: "center",
}));

export const TransparentMediaText = styled(Box)<BoxProps>(({ theme }) => ({
  // position: "absolute",
  color: "white",
  width: "100%",
  // left: "50%",
  // bottom: 0,
  // transform: "translateX(-50%)",
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  textAlign: "center",
  backgroundColor: "rgba(0,0,0,0.95)",
}));
