import { Timestamp } from "firebase/firestore";

export enum ReservationStep {
  INITIAL_STEP,
  TIME_SELECTED,
  PLAYER_COUNT_SELECTED,
  GENERAL_INFORMATION_PROVIDED,
  RESERVATION_COMPLETE,
}

export enum EscapeRoomsIds {
  NOT_SELECTED = -1,
  EXPERIMENT_23,
  DUMMY,
  DUMMY2,
}

export type ReservableTime =
  | "10:00"
  | "12:00"
  | "14:00"
  | "16:00"
  | "18:00"
  | "20:00"
  | any;

export type ReservationTimes = {
  [date: string]: ReservableTime[];
};

export type TimeReservationSnapshot = {
  availableTimesPerDay: ReservableTime[];
  reservedTimes: ReservationTimes;
};

export type DisplayReservationTimes = {
  [date: string]: { time: ReservableTime; available: boolean }[];
};

export type AvailabilitySnapshot = {
  grandOpeningDate?: Timestamp;
  daysOff: Timestamp[];
  hourLimitations: HourLimitations;
};

export type HourLimitations = {
  monday: number[];
  tuesday: number[];
  wednesday: number[];
  thursday: number[];
  friday: number[];
  saturday: number[];
  sunday: number[];
};
