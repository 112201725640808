import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";
import { DisplayReservationTimes } from "../../../types";

type CustomPickerDayProps = {
  reservationTimesData?: DisplayReservationTimes;
} & PickersDayProps<dayjs.Dayjs>;

const CustomPickerDay: React.FC<CustomPickerDayProps> = (props) => {
  const day = props.day.date().toString().padStart(2, "0"); // Get the day (1-31)
  const month = (props.day.month() + 1).toString().padStart(2, "0"); // Get the month (0-11) and add 1
  const year = props.day.year(); // Get the year (e.g., 2023)

  const dayKey = `${day}-${month}-${year}`;

  let dotCount;

  if (!props.reservationTimesData || !props.reservationTimesData[dayKey]) {
    dotCount = 0;
  } else {
    dotCount = props.reservationTimesData[dayKey].reduce(
      (acc, obj) => (obj.available ? acc + 1 : acc),
      0
    );
  }

  if (props.selected) {
    dotCount = 0;
  }

  return (
    <PickersDay
      {...props}
      sx={{
        "&:after": {
          content: '""',
          display: "block",
          position: "absolute",
          bottom: "-2px",
          width: "100%",
          textAlign: "center",
          color: "lightgreen",
          whiteSpace: "pre",
          lineHeight: "6px",
        },
        '&[data-dots="1"]:after': {
          content: '"•"',
          bottom: "4px",
        },
        '&[data-dots="2"]:after': {
          content: '"••"',
          bottom: "4px",
        },
        '&[data-dots="3"]:after': {
          content: '"•••"',
          bottom: "4px",
        },
        '&[data-dots="4"]:after': {
          content: '"•••\\A•"',
        },
        '&[data-dots="5"]:after': {
          content: '"•••\\A••"',
        },
        '&[data-dots="6"]:after': {
          content: '"•••\\A•••"',
        },
      }}
      data-dots={dotCount}
    />
  );
};

export default CustomPickerDay;
