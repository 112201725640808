import {
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";

type FAQCardComponentProps = {
  title: string;
  description: string;
};

const FAQCardComponent: React.FC<FAQCardComponentProps> = ({
  title,
  description,
}) => {
  return (
    <Card
      sx={{
        borderRadius: 5,
        transition: "0.25s",
        ":hover": {
          transform: "scale(1.02)",
          boxShadow: `0 2px 20px 4px rgb(69, 69, 69)`,
        },

        opacity: 0.95,
      }}
    >
      <CardContent>
        <Stack m={1} spacing={2}>
          <Typography variant="h4">{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </CardContent>

      <CardActions></CardActions>
    </Card>
  );
};

export default FAQCardComponent;
