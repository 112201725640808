import dayjs from "dayjs";
import {
  Action,
  Thunk,
  action,
  createStore,
  createTypedHooks,
  thunk,
} from "easy-peasy";
import { doc, getDoc } from "firebase/firestore";
import _, { map, toNumber } from "lodash";
import { isToday } from "./components/Reservation/helpers";
import { firestoreDB } from "./fire";
import {
  AvailabilitySnapshot,
  DisplayReservationTimes,
  EscapeRoomsIds,
  HourLimitations,
  ReservableTime,
  ReservationStep,
  TimeReservationSnapshot,
} from "./types";

const MAX_DAYS_IN_FUTURE = 61;

type ReservationState = {
  step: number;
  selectedDate: dayjs.Dayjs;
  selectedEscapeRoomId: number;
  nrOfPlayers?: number;
  clientName?: string;
  clientEmail?: string;
  clientPhone?: string;
  clientLanguage?: string;
  reservationTimeId?: number;
  acceptedTerms?: boolean;
  reservationTimes: {
    data?: DisplayReservationTimes;
    isLoading: boolean;
    isLoaded: boolean;
    reservationPossibilitiesPerDay?: ReservableTime[];
  };
  reservationTime?: string;
  reCaptchaToken: string | null;
  isLoading?: boolean;
  reservationSuccessful?: boolean;
  reservationErrorMessage?: string;
};

type MetadataState = {
  availability: AvailabilitySnapshot;
  availableTimesPerDay?: ReservableTime[];
};

export type KultState = {
  clientName: string;
  clientPhone: string;
  timeSelected: string;
  clientMail: string;
  kultAvailableTimes?: string[];
  isLoading?: boolean;
  reservationSuccessful?: boolean;
};

type KultDoc = {
  availableTimes: string[];
  reservedTimes: string[];
};

type HandleTimeSelectedPayload = {
  selectedEscapeRoomId: EscapeRoomsIds;
  reservationTimeId: number;
  time: ReservableTime;
};

type StoreModel = {
  reservationState: ReservationState;
  metadataState: MetadataState;
  kultState: KultState;
  handleTimeSelected: Action<StoreModel, HandleTimeSelectedPayload>;
  setNrOfPlayers: Action<StoreModel, number | undefined>;
  updateFields: Action<StoreModel, Partial<ReservationState>>;
  updateMetadata: Action<StoreModel, Partial<MetadataState>>;
  updateKult: Action<StoreModel, Partial<KultState>>;
  loadReservationTimes: Thunk<StoreModel, void>;
  updateReservationTimes: Action<
    StoreModel,
    Partial<ReservationState["reservationTimes"]>
  >;
  reset: Action<StoreModel, void>;
};

const initialState: ReservationState = {
  nrOfPlayers: 4,
  step: ReservationStep.INITIAL_STEP,
  selectedDate: dayjs(new Date()),
  selectedEscapeRoomId: EscapeRoomsIds.NOT_SELECTED,
  reservationTimes: { data: {}, isLoading: false, isLoaded: false },
  reCaptchaToken: null,
  reservationErrorMessage: undefined,
};

const initialMetadataState: MetadataState = {
  availability: {
    daysOff: [],
    grandOpeningDate: undefined,
    hourLimitations: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  },
};

const initialKultState: KultState = {
  clientName: "",
  clientPhone: "",
  timeSelected: "",
  clientMail: "",
};

export const store = createStore<StoreModel>({
  reservationState: initialState,
  metadataState: initialMetadataState,
  kultState: initialKultState,
  handleTimeSelected: action((state, payload) => {
    state.reservationState.step = ReservationStep.TIME_SELECTED;
    state.reservationState.selectedEscapeRoomId = payload.selectedEscapeRoomId;
    state.reservationState.reservationTimeId = payload.reservationTimeId;

    const reservationPossibilities =
      state.reservationState.reservationTimes.reservationPossibilitiesPerDay;

    if (reservationPossibilities) {
      state.reservationState.reservationTime = payload.time;
    }
  }),

  setNrOfPlayers: action((state, payload) => {
    state.reservationState.nrOfPlayers = payload;
  }),
  updateFields: action((state, payload) => {
    state.reservationState = { ...state.reservationState, ...payload };
  }),
  updateMetadata: action((state, payload) => {
    state.metadataState = { ...state.metadataState, ...payload };
  }),
  updateReservationTimes: action((state, payload) => {
    state.reservationState.reservationTimes = {
      ...state.reservationState.reservationTimes,
      ...payload,
    };
  }),
  loadReservationTimes: thunk(async (actions, payload) => {
    actions.updateReservationTimes({ isLoading: true });

    // KULT PART - remove later
    const kultDocRef = doc(firestoreDB, "reservation", "kultFest");

    const kultObject = (await getDoc(kultDocRef)).data() as KultDoc;

    actions.updateKult({
      kultAvailableTimes: _.difference(
        kultObject.availableTimes,
        kultObject.reservedTimes
      ),
    });

    //  KULT ends

    const reservationDocRef = doc(
      firestoreDB,
      "reservation",
      "reservationTimes"
    );

    const availabilityRef = doc(firestoreDB, "metadata", "availability");

    const reservationTimesSnapshot = await getDoc(reservationDocRef);

    const availabilitySnapshot = await getDoc(availabilityRef);

    const reservationTimesObject =
      reservationTimesSnapshot.data() as TimeReservationSnapshot;

    const availabilityObject =
      availabilitySnapshot.data() as AvailabilitySnapshot;

    const reservationTimes: DisplayReservationTimes = {};

    actions.updateMetadata({
      availability: availabilityObject,
      availableTimesPerDay: reservationTimesObject.availableTimesPerDay,
    });

    const currentDate = new Date();
    const grandOpeningDate = availabilityObject.grandOpeningDate?.toDate();

    for (let index = 0; index < MAX_DAYS_IN_FUTURE; index++) {
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();

      const isCurrentDateToday = isToday(currentDate);

      const reservationKey = `${day.toString().padStart(2, "0")}-${month
        .toString()
        .padStart(2, "0")}-${year}`;

      if (grandOpeningDate && currentDate < grandOpeningDate) {
        reservationTimes[reservationKey] = [];
        currentDate.setDate(currentDate.getDate() + 1);
        continue;
      }

      let skipDay = false;
      for (const offTimeKey in availabilityObject.daysOff) {
        const offTimeItem = availabilityObject.daysOff[offTimeKey].toDate();
        if (
          offTimeItem.getDate() === currentDate.getDate() &&
          offTimeItem.getMonth() === currentDate.getMonth() &&
          offTimeItem.getFullYear() === currentDate.getFullYear()
        ) {
          skipDay = true;
          break;
        }
      }

      if (skipDay) {
        reservationTimes[reservationKey] = [];
        currentDate.setDate(currentDate.getDate() + 1);
        continue;
      }

      const reservedTimesCounterpart =
        reservationTimesObject.reservedTimes[reservationKey];

      const filteredAvailableTimes = filterAvailabilityByDay(
        currentDate.getDay(),
        reservationTimesObject.availableTimesPerDay,
        availabilityObject.hourLimitations
      );

      const mergedAvailableTimes = _.union(
        filteredAvailableTimes,
        reservedTimesCounterpart
      );

      reservationTimes[reservationKey] = map(mergedAvailableTimes, (time) => {
        if (isCurrentDateToday) {
          const fourHoursInFuture = currentDate.getHours() + 6;

          return {
            time,
            available:
              !!reservedTimesCounterpart &&
              reservedTimesCounterpart.includes(time)
                ? false
                : fourHoursInFuture < toNumber(time.split(":")[0]),
          };
        }

        return {
          time,
          available:
            !!reservedTimesCounterpart &&
            reservedTimesCounterpart.includes(time)
              ? false
              : true,
        };
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    actions.updateReservationTimes({
      data: reservationTimes,
      isLoaded: true,
      isLoading: false,
      reservationPossibilitiesPerDay:
        reservationTimesObject.availableTimesPerDay,
    });
  }),
  reset: action((state) => {
    state.reservationState = { ...initialState };
    state.reservationState.step = ReservationStep.INITIAL_STEP;
  }),
  updateKult: action((state, payload) => {
    state.kultState = { ...state.kultState, ...payload };
  }),
});

export const typedStoreHooks = createTypedHooks<StoreModel>();

const dayOfWeekAssociationTable = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
} as const;

const filterAvailabilityByDay = (
  dayOfWeekIndex: number,
  initialAvailability: ReservableTime[],
  hourLimitations: HourLimitations
): ReservableTime[] => {
  const limitationsForDay =
    hourLimitations[
      dayOfWeekAssociationTable[
        dayOfWeekIndex as keyof typeof dayOfWeekAssociationTable
      ] as keyof HourLimitations
    ];

  return initialAvailability.filter((time) => {
    // Extract the hour part from the time string
    const hour = parseInt(time.split(":")[0]);

    // Check if the hour is between the given numbers
    return hour >= limitationsForDay[0] && hour <= limitationsForDay[1];
  });
};
