import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Stack, Typography, Box, IconButton } from "@mui/material";
import PresenterCardComponent from "./PresenterCardComponent";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

import TeamworkImage from "../../assets/images/cardImage_Brown_teamwork.jpg";
import MysteryImage from "../../assets/images/cardImage_Teal_Mystery.jpg";
import AdventureImage from "../../assets/images/cardImage_Orange_Adventure.jpg";
import ProblemSolvingImage from "../../assets/images/cardImage_Purple_problemSolving.jpg";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

enum DesignCard {
  MYSTERY,
  ADVENTURE,
  TEAMWORK,
  CHALLENGE,
}

const AboutComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDesignCard, setSelectedDesignCard] = useState(-1);

  const navigateToReservations = useCallback(() => {
    navigate("/reservation");
  }, [navigate]);

  return (
    <Stack
      id="about"
      sx={{ alignItems: { xs: "center", md: "start" } }}
      width={{ lg: 1200, md: 900, sm: 700, xs: 350 }}
    >
      <Stack
        spacing={4}
        sx={{
          mt: 12,

          width: { md: "680px", xs: "300px" },
          alignItems: { md: "start", xs: "center" },
          textAlign: { md: "start", xs: "center" },
        }}
      >
        <Typography variant="h2">
          {t("about.title.part1", { ns: "common" })}
          <Typography variant="h2" color="primary" display="inline">
            {t("about.title.part2", { ns: "common" })}
          </Typography>{" "}
          {t("about.title.part3", { ns: "common" })}
          <Typography variant="h2" color="primary" display="inline">
            {t("about.title.part4", { ns: "common" })}
          </Typography>
        </Typography>
        <Typography variant="h5">
          {t("about.description", { ns: "common" })}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          sx={{ borderRadius: 20, width: "240px" }}
          endIcon={<ArrowForwardIcon />}
          onClick={navigateToReservations}
        >
          {t("about.toGames", { ns: "common" })}
        </Button>
      </Stack>
      <Stack
        spacing={4}
        sx={{ mb: 0, mt: 12 }}
        alignItems={{ xs: "center", md: "start" }}
      >
        <Typography variant="h2">
          {t("about.whatIsItTitle.part1", { ns: "common" })}
          <Typography variant="h2" color="primary" display="inline">
            {t("about.whatIsItTitle.part2", { ns: "common" })}
          </Typography>{" "}
          {t("about.whatIsItTitle.part3", { ns: "common" })}
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="center"
          mr={{ md: 12, xs: 0 }}
          mt={8}
          pt={6}
          useFlexGap
          flexWrap="wrap"
        >
          <PresenterCardComponent
            title={t("about.escapeCards.mystery.title", { ns: "common" })}
            description={
              selectedDesignCard === DesignCard.MYSTERY
                ? t("about.escapeCards.mystery.description", {
                    ns: "common",
                  })
                : undefined
            }
            imageProps={{ image: MysteryImage }}
            paperColor="rgb(44, 78, 88)"
            onClick={() => setSelectedDesignCard(DesignCard.MYSTERY)}
          />
          <PresenterCardComponent
            title={t("about.escapeCards.adventure.title", { ns: "common" })}
            description={
              selectedDesignCard === DesignCard.ADVENTURE
                ? t("about.escapeCards.adventure.description", {
                    ns: "common",
                  })
                : undefined
            }
            imageProps={{ image: AdventureImage }}
            paperColor="rgb(217, 87, 32)"
            onClick={() => setSelectedDesignCard(DesignCard.ADVENTURE)}
          />
          <PresenterCardComponent
            title={t("about.escapeCards.teamwork.title", { ns: "common" })}
            description={
              selectedDesignCard === DesignCard.TEAMWORK
                ? t("about.escapeCards.teamwork.description", {
                    ns: "common",
                  })
                : undefined
            }
            imageProps={{ image: TeamworkImage }}
            paperColor="rgb(81, 50, 30)"
            onClick={() => setSelectedDesignCard(DesignCard.TEAMWORK)}
          />
          <PresenterCardComponent
            title={t("about.escapeCards.challenge.title", { ns: "common" })}
            description={
              selectedDesignCard === DesignCard.CHALLENGE
                ? t("about.escapeCards.challenge.description", {
                    ns: "common",
                  })
                : undefined
            }
            imageProps={{ image: ProblemSolvingImage }}
            paperColor="rgb(11, 14, 71)"
            onClick={() => setSelectedDesignCard(DesignCard.CHALLENGE)}
          />
        </Stack>
        <Box height={64}></Box>
        <Stack
          spacing={4}
          alignItems="center"
          mt={12}
          id="contact"
          width="100%"
        >
          <Typography variant="h4">
            {t("about.contact.title", { ns: "common" })}
          </Typography>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{
              marginLeft: "16px !important",
              marginRight: "16px !important",
            }}
          >
            {t("about.contact.description", { ns: "common" })}
          </Typography>
          <Typography variant="h5" textAlign="center">
            {t("about.contact.phoneNum", { ns: "common" })}
          </Typography>
          <Stack
            spacing={4}
            direction={{ md: "row", xs: "column" }}
            justifyContent="space-between"
            width="80%"
          >
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              sx={{
                marginLeft: "16px !important",
                marginRight: "16px !important",
              }}
            >
              <Typography variant="h6">
                {t("about.contact.meetUs", { ns: "common" })}{" "}
              </Typography>
              <IconButton
                aria-label="facebook"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/people/PlayFull-escape-room-adventures/100092502337920/",
                    "_blank" // <- This is what makes it open in a new window.
                  )
                }
              >
                <FacebookIcon sx={{ width: 40, height: 40 }} />
              </IconButton>
              <IconButton
                aria-label="facebook"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/playfull_escape/",
                    "_blank" // <- This is what makes it open in a new window.
                  )
                }
              >
                <InstagramIcon sx={{ width: 40, height: 40 }} />
              </IconButton>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <MailOutlineIcon sx={{ width: 40, height: 40 }} />
              <Typography variant="h6">playfullescape@gmail.com</Typography>
            </Stack>
          </Stack>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{
              marginLeft: "16px !important",
              marginRight: "16px !important",
            }}
          >
            {t("about.contact.address", { ns: "common" })}
          </Typography>
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              marginLeft: "16px !important",
              marginRight: "16px !important",
            }}
          >
            {t("about.contact.parking", { ns: "common" })}
          </Typography>

          <Box sx={{ width: "100%" }}>
            <iframe
              style={{ borderRadius: 10 }}
              title="google-maps-playfull"
              width="100%"
              height="400"
              frameBorder="0"
              scrolling="no"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Targu%20Mures,%20str.%20Revolutiei,%20nr.%2045,%20Mures,%20540042+(Playfull)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/population/">Population mapping</a>
            </iframe>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AboutComponent;
